import $ from 'jquery';
import "slick-carousel";
import YouTubePlayer from "youtube-player";
import MatchHeight from "../modules/match_height";

export default function (options) {
   /*
   USAGE:

   DESCRIPTION
   YouTube videos with sidebar playlist (powered by a vertical Slick slider)


   */


   //
   //   Private Vars
   //
   //////////////////////////////////////////////////////////////////////

   var self = {
      player: null
   };



   //
   //   Public Vars
   //
   //////////////////////////////////////////////////////////////////////

   self.settings = $.extend({
      $playlist: $('.js-youtube-playlist'),
      $sidebar: $('.js-youtube-playlist-sidebar'),
      $video: $('.js-youtube-playlist-video'),
      matchHeightSelector: '.js-match-height-playlist',
      playlistItemSelector: '.js-youtube-playlist-item',
      classActive: 'is-active'
   }, options);



   //
   //   Private Methods
   //
   //////////////////////////////////////////////////////////////////////

   var _init = function() {
      _apiCall();
   };

   var _addEventListeners = function() {
      $(self.settings.playlistItemSelector).click(_onPlaylistItemClick);
      self.player.on('stateChange', _onYouTubePlayerStateChange);
      self.player.on('ready', _onYouTubePlayerReady);
   };

   var _apiCall = function() {
      var youtubeApiUrl = _getYoutubeApiUrl();

      $.ajax({
         url: youtubeApiUrl,
         success: _apiResponseHandler
      });
   };

   var _apiResponseHandler = function(response) {
      // Grab playlist videos
      var playlist = response.items;
      var firstVideo = response.items[0];

      // Loop through playlist and add videos
      $.each(playlist,function(i,video) {
         _addPlaylistVideo(video.snippet);
      });

      // Add first video to our playlist module
      _addFirstVideo(firstVideo.snippet);

      // Final Playlist prep
      _onPlaylistReady();
   };

   var _getYoutubeApiUrl = function() {
      // Set params
      var mykey = 'AIzaSyA-4hT_dBls06aTZJiLrZk0YIKA_3FrEWM';
      var playListID = self.settings.$playlist.data('playlist-id');

      // Construct an return URL
      var URL = 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId='+ playListID +'&key=' + mykey;
      return URL;
   };

   var _addPlaylistVideo = function(videoJson) {
      var videoId = videoJson.resourceId.videoId;
      var thumbnailUrl = videoJson.thumbnails.high.url;
      var alt = videoJson.title;
      var video = '<div class="youtube-playlist__playlist-item js-youtube-playlist-item" data-video-id="'+ videoId +'">' +
          '<img class="lazysizes" data-src="' + thumbnailUrl + '" alt="' + alt + '">' +
          '</div>'
      self.settings.$sidebar.append(video);
   };

   var _addFirstVideo = function(videoJson) {
      // Add iframe for first video
      var videoId = videoJson.resourceId.videoId;

      // Create YouTube player
      self.player = YouTubePlayer('youtube-player', {
         videoId: videoId
      });

      // Set first thumb as active
      $(self.settings.playlistItemSelector).first().addClass(self.settings.classActive);
   };

   var _onPlaylistReady = function() {
      _addEventListeners();

      self.settings.$sidebar.slick({
         appdendArrows: self.settings.$sidebar.parent(),
         dots: false,
         infinite: false,
         slidesToShow: 1,
         slidesToScroll: 1,
         vertical: true,
         verticalSwiping: true
      });
   };

   var _onPlaylistItemClick = function(evt) {
      // Update active slide
      $(self.settings.playlistItemSelector).removeClass(self.settings.classActive);
      $(this).addClass(self.settings.classActive);

      // Update player with selected video
      var videoId = $(this).data('video-id');
      _loadNewVideo(videoId);
   }

   var _onYouTubePlayerReady = function(evt) {
      new MatchHeight({
         $el: $(self.settings.matchHeightSelector)
      });
   }

   var _onYouTubePlayerStateChange = function(evt) {
      if(evt.data === 0) {
         _loadNextVideo();
      }
   }

   var _loadNextVideo = function() {
      var $activeVideo = self.settings.$sidebar.find('.' + self.settings.classActive);
      var $activeVideoParent = $activeVideo.closest('.slick-slide');
      $activeVideoParent.next().find('.js-youtube-playlist-item').click();
   }

   var _loadNewVideo = function(videoId) {
      self.player.loadVideoById(videoId);
   }

   var _getIframeUrl = function(videoId) {
      return url = 'https://www.youtube.com/embed/' + videoId +'?enablejsapi=1';
   }


   //
   //   Public Methods
   //
   //////////////////////////////////////////////////////////////////////

   // Initiate
   _init();

   // Return the Object
   return self;
}
